import loadable from '@loadable/component';

import { EventBattle } from '../../../types/Battle';

import { getHawk } from '../../../utils/global';
import { Site } from '../../modules/sites/types/Site';

const lazyGetCustomTrackingIdServerside = loadable.lib(
  () => import('../../../analytics-library/entry/getCustomTrackingIdServerside'),
);
const lazyGetCustomTrackingId = loadable.lib(
  () => import('../../../analytics-library/entry/getCustomTrackingId'),
);
const lazyXmlDom = loadable.lib(() => import('xmldom'));
const lazySanitizeHtml = loadable.lib(() => import('sanitize-html'));

export const getCustomTrackedWidgetIntroduction = async (
  widgetIntroduction: string,
  articleUrl: string,
  site: Site,
  area: string,
  battle: EventBattle | null,
): Promise<string> => {
  let customTrackingId: string;
  let serializer;
  const { customTrackingId: forcedCustomTrackingId } = getHawk();
  let parser: DOMParser;
  if (process.env.IS_SERVERSIDE) {
    const { DOMParser, XMLSerializer } = await lazyXmlDom.load();
    parser = new DOMParser();
    serializer = new XMLSerializer();
    // We need to sanitize HTML for xmldom library
    const sanitizeHtml = await lazySanitizeHtml.load();
    widgetIntroduction = sanitizeHtml.default(widgetIntroduction);
  } else {
    parser = new DOMParser();
  }

  const doc = parser.parseFromString(widgetIntroduction, 'text/html');
  const linksPromises = Array.from(doc.getElementsByTagName('a')).map(async (link) => {
    try {
      if (process.env.IS_SERVERSIDE) {
        const { getCustomTrackingIdServerside } =
          (await lazyGetCustomTrackingIdServerside.load()) as any;
        customTrackingId = getCustomTrackingIdServerside(
          {
            pageUrl: articleUrl,
            site,
            // It is impossible to get all attributes serverside so we omit them
            attributes: {},
            area,
            battle,
          },
          forcedCustomTrackingId,
        );
      } else {
        const attributes = link.getAttributeNames().reduce(
          (acc, name) => ({
            ...acc,
            [name]: link.getAttribute(name),
          }),
          {},
        );
        const { getCustomTrackingId } = (await lazyGetCustomTrackingId.load()) as any;
        customTrackingId = await getCustomTrackingId(
          {
            pageUrl: articleUrl,
            site,
            attributes,
            area,
            battle,
          },
          forcedCustomTrackingId,
        );
      }
    } catch (_) {
      return link;
    }

    link.setAttribute('data-custom-tracking-id', customTrackingId);
    return link;
  });

  await Promise.all(linksPromises);

  if (process.env.IS_SERVERSIDE) {
    return serializer.serializeToString(doc);
  }

  return doc.body.innerHTML;
};
