import { getPromoItems, PromoType } from '../../../sharedModules/getPromoItems';
import type { LabelPromo } from '../../../types/LabelPromo';
import type { DealData } from '../../types/DealData';
import type { WidgetProps } from '../../types/WidgetProps';

export const getPromos = (
  deal: DealData['deals'][number],
  platform: string,
  translate: WidgetProps['translate'],
  starLabel?: string,
): LabelPromo[] => {
  const promoItems = getPromoItems(deal, PromoType.ALLOWED, false);
  const promoItemStarLabel = starLabel
    ? getPromoItems(
        {
          promos: [
            {
              type: platform === 'responsive' ? 'hero' : 'hero-progressive',
              value: translate(starLabel),
              display_value: translate(starLabel),
            },
          ],
        },
        PromoType.ALLOWED,
        false,
      )
    : [];

  return promoItems.concat(promoItemStarLabel);
};
