import { HawkWidgetProviderContext } from '../GenericComponents/HawkWidget/model';

export enum DealWidgetTitleStructureProperties {
  MODEL_BRAND = 'modelBrand',
  MODEL_PRODUCT_NAME = 'modelProductName',
  FORMATTED_WAS_PRICE = 'formattedWasPrice',
  FORMATTED_PRICE = 'formattedPrice',
  DATA_LINK_MERCHANT = 'dataLinkMerchant',
}

export interface DealWidgetTitleStructure {
  [DealWidgetTitleStructureProperties.MODEL_BRAND]: HawkWidgetProviderContext['modelBrand'];
  [DealWidgetTitleStructureProperties.MODEL_PRODUCT_NAME]: HawkWidgetProviderContext['modelProductName'];
  [DealWidgetTitleStructureProperties.FORMATTED_WAS_PRICE]: string | null;
  [DealWidgetTitleStructureProperties.FORMATTED_PRICE]: string | null;
  [DealWidgetTitleStructureProperties.DATA_LINK_MERCHANT]: HawkWidgetProviderContext['dataLinkMerchant'];
}
