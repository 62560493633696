export const getFirstNotNullishProperty = <P extends object>(
  object: P,
): null | { name: keyof P; value: P[keyof P] } => {
  const entries = Object.entries(object) as [keyof P, P[keyof P]][];

  for (const [name, value] of entries) {
    if (value) {
      return { name, value };
    }
  }

  return null;
};
