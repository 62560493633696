export const shouldHideDealWidgetTitle = ({
  modelBrand,
  modelProductName,
  formattedPrice,
  formattedWasPrice,
}): boolean => {
  const rules = [
    [!modelBrand, !modelProductName, !formattedPrice],
    [!modelProductName, !formattedWasPrice, !formattedPrice],
  ];

  for (const rule of rules) {
    if (rule.every((key) => key)) {
      return true;
    }
  }

  return false;
};
