import React from 'react';

import classNames from 'classnames';

import { getClickType } from '../../../../sharedModules/getClickType';
import { Deal } from '../../../types/Deal';
import { getFirstNotNullishProperty } from '../../../utils/getFirstNotNullishProperty';
import DisplayPriceCode from '../../GenericComponents/DisplayPrice/DisplayPriceCode';
import { getFormattedPrice, getPrice } from '../../GenericComponents/DisplayPrice/getPrice';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { DealWidgetTitleStructure, DealWidgetTitleStructureProperties } from '../types';

import { addWhiteSpace } from './addWhitespace';
import { removeSkimlinksPrefix } from './removeSkimlinksPrefix';
import { shouldHideDealWidgetTitle } from './shouldHideDealWidgetTitle';
import styles from './styles/deal-widget-title.css';

interface DealWidgetTitleProps {
  deal: Deal;
}

export const DealWidgetTitle: React.FC<DealWidgetTitleProps> = ({ deal }) => {
  const {
    articleUrl,
    dataLinkMerchant,
    language,
    showPriceRounded,
    modelProductName,
    modelBrand,
    translate,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  const modifiedDataLinkMerchant = removeSkimlinksPrefix(dataLinkMerchant);

  const formattedPrice = getFormattedPrice(
    getPrice(deal, 'retail'),
    deal,
    false,
    'retail',
    language,
    translate,
    showPriceRounded,
    false,
  );

  const formattedWasPrice = getFormattedPrice(
    getPrice(deal, 'wasPrice'),
    deal,
    false,
    'wasPrice',
    language,
    translate,
    showPriceRounded,
    false,
  );

  if (
    shouldHideDealWidgetTitle({
      modelBrand,
      modelProductName,
      formattedWasPrice,
      formattedPrice,
    })
  ) {
    return null;
  }

  const shouldDisplayModelBrand = modelBrand && modelProductName;
  const firstInTitle = getFirstNotNullishProperty<Partial<DealWidgetTitleStructure>>({
    modelProductName,
    formattedWasPrice,
    formattedPrice,
  });

  const isModelBrandFirst = firstInTitle?.name === DealWidgetTitleStructureProperties.MODEL_BRAND;
  const isModelProductNameFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.MODEL_PRODUCT_NAME;
  const isWasPriceFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_WAS_PRICE;
  const isPriceFirst = firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_PRICE;
  const isMerchantFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.DATA_LINK_MERCHANT;

  return (
    <>
      <AffiliateLink
        deal={deal}
        clickType={getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })}
      >
        <span className={styles['product-title']}>
          {shouldDisplayModelBrand && (
            <span className={isModelBrandFirst ? styles['capitalize'] : {}}>{modelBrand}</span>
          )}
          {modelProductName && (
            <>
              {addWhiteSpace(Boolean(shouldDisplayModelBrand))}
              <span className={isModelProductNameFirst ? styles['capitalize'] : {}}>
                {modelProductName}
                {!modelProductName.endsWith(':') && ':'}
              </span>
            </>
          )}
        </span>
      </AffiliateLink>
      {formattedWasPrice ? (
        <>
          {addWhiteSpace(!isWasPriceFirst)}
          <AffiliateLink
            deal={deal}
            clickType={getClickType({
              link: deal.offer.link,
              articleUrl,
              productType: deal.product_type,
            })}
          >
            <span
              className={classNames(
                styles['was-price'],
                isWasPriceFirst ? styles['capitalize'] : {},
              )}
            >
              {translate('was')} <DisplayPriceCode currencyIso={deal?.offer?.currency_iso} />
              {formattedWasPrice}
            </span>
          </AffiliateLink>
        </>
      ) : null}
      <AffiliateLink
        deal={deal}
        clickType={getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })}
      >
        {addWhiteSpace(!isPriceFirst)}
        <span className={styles['retailer-price']}>
          {formattedPrice ? (
            <>
              <span
                className={classNames(styles['price'], isPriceFirst ? styles['capitalize'] : {})}
              >
                {formattedWasPrice ? translate('now') : null}{' '}
                <DisplayPriceCode currencyIso={deal?.offer?.currency_iso} />
                {formattedPrice}
              </span>
            </>
          ) : null}
          {modifiedDataLinkMerchant ? (
            <>
              {addWhiteSpace(!isMerchantFirst)}
              <span
                className={classNames(
                  styles['retailer'],
                  isMerchantFirst ? styles['capitalize'] : {},
                )}
              >
                {translate('at')} {modifiedDataLinkMerchant}
              </span>
            </>
          ) : null}
        </span>
      </AffiliateLink>
    </>
  );
};
