import type { DOMNode, Element } from 'html-react-parser';

/**
 * Custom typeguard to replace 'domNode instanceof Element'
 * https://github.com/remarkablemark/html-react-parser/issues/221
 */
export const isElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;

  return isTag && hasAttributes;
};
